import React from "react";
import "./SocialLinks.css";

const socialMediaLinks = [
  {
    url: "https://twitter.com/TWilliams_Dev",
    image: "/images/X.png",
    alt: "Go to Taylor's X (Twitter) profile",
  },
  {
    url: "https://www.youtube.com/@TWilliams_Dev",
    image: "/images/YouTube/YT.png",
    alt: "Go to the my YouTube Channel",
  },
  {
    url: "https://www.instagram.com/twilliams_dev",
    image: "/images/Instagram.svg",
    alt: "Go to Taylor's Instagram profile"
  },
  {
    url: "https://www.threads.net/@tmwilliams96",
    image: "/images/Threads.svg",
    alt: "Go to Taylor's Threads profile",
  },
  {
    url: "https://www.linkedin.com/in/taylorwilliams94",
    image: "/images/Linkedin.png",
    alt: "Go to Taylor's LinkedIn profile",
  },
  {
    url: "https://github.com/twillia7",
    image: "/images/Github.png",
    alt: "Go to Taylor's Github profile",
    style: { borderRadius: "50%" },
  },
];

const SocialLink = ({ url, image, alt, style }) => (
  <a target="_blank" rel="noreferrer" href={url} style={{ display: 'flex', alignItems: 'center' }}>
    <div>
      <img className="social-image" src={image} alt={alt} style={style} />
    </div>
  </a >
);

function SocialLinks() {
  return (
    <div style={{ padding: "12px" }}>
      <div id="social-links-container">
        <div className="social-container">
          {socialMediaLinks.map((link, index) => (
            <SocialLink key={`social-${index}`} {...link} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default SocialLinks;
